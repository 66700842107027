import { Button, Modal } from "../components"
import { CloseCircle } from "../css/icons"
import styles from "./UnsealModal.module.scss"

const UnsealModalMobile = ({ close, unsealMobile, deriveKey, token, holderKey }) => (
  <Modal onClose={() => close()} width="medium">
    <div className={styles.unsealModalContainer}>
      <CloseCircle className={styles.close} onClick={() => close()} />
      <div className={styles.warningIcon}>⚠️</div>
      <div className={styles.unsealWarning}>
        Are you sure you want to break the seal?
      </div>
      <img
        src={token?.metadata?._json.image}
        alt="sealed"
        className={styles.image}
      />

      <div className={styles.subtext}>
        Breaking a Tamperproof seal is permanent and can only be done once.
      </div>
      <div className={styles.subtext}>
        You will not be able to trade or sell the Tamperproof NFT prize after
        breaking the seal.
      </div>
      <div className={styles.subtext}>
        Others will be able to see that this NFT seal has been broken in its
        metadata.
      </div>
      <br />
      <div className={styles.buttonContainer}>
        <div className={styles.link} onClick={() => close()}>
          No, keep it sealed
        </div>
        {holderKey ? (
          <Button variant="green" onClick={() => unsealMobile()}>
            Break Seal & Reveal Code
          </Button>
        ) : (
          <Button variant="green" onClick={() => deriveKey()}>
            Derive Key to Unseal
          </Button>
        )}
      </div>
    </div>
  </Modal>
)

export default UnsealModalMobile
