export const Sealed = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_911_5200)">
      <path
        d="M10.3214 5.25L9.73214 5.25L9.73214 3.5625C9.73214 1.59844 8.05759 6.60705e-07 6 4.80825e-07C3.94241 3.00945e-07 2.26786 1.59844 2.26786 3.5625L2.26786 5.25L1.67857 5.25C1.0279 5.25 0.499999 5.75391 0.499999 6.375L0.499999 10.875C0.499999 11.4961 1.0279 12 1.67857 12L10.3214 12C10.9721 12 11.5 11.4961 11.5 10.875L11.5 6.375C11.5 5.75391 10.9721 5.25 10.3214 5.25ZM7.76786 5.25L4.23214 5.25L4.23214 3.5625C4.23214 2.63203 5.02522 1.875 6 1.875C6.97478 1.875 7.76786 2.63203 7.76786 3.5625L7.76786 5.25Z"
        fill="#43BA7D"
      />
    </g>
    <defs>
      <clipPath id="clip0_911_5200">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
