import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import { useWallet } from "@solana/wallet-adapter-react"
import { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Layout } from "../components"
import Loading from "../Loading/Loading"
import styles from "./Home.module.scss"
import { getTensorAccounts } from "../helpers/getTensorAccounts"

const Home = () => {
  const history = useHistory()
  const adapter = useWallet()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (adapter.publicKey) {
      checkForNFTs()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter.publicKey) {
      checkForNFTs()
    }
  }, [adapter.publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForNFTs = async () => {
    setLoading(true)
    let nfts = await getTensorAccounts(adapter)
    console.log("nfts", nfts)
    if (nfts.length === 0) {
      history.replace({
        pathname: "/nfts",
        state: {
          mints: [],
          empty: true,
        },
      })
    } else if (nfts.length == 1) {
      let nft = nfts[0]
      console.log("nft is", nft)
      history.replace({
        pathname: `/nft/${nft.mint.address.toString()}`,
      })
    } else {
      history.replace({
        pathname: "/nfts",
      })
    }
  }

  const publicKeySliced = () => {
    if (!adapter?.publicKey) return ""
    let str = adapter.publicKey.toString()
    if (!adapter?.publicKey) return ""
    let start = str.substr(0, 4)
    let end = str.substr(str.length - 4, str.length)
    return `${start}...${end}`
  }

  return (
    <Layout className={styles.homePageContainer}>
      <div className={styles.homeContent}>
        <img
          src="https://cdn.hngr.co/tpinverted.png"
          alt="Tamperproof logo"
        />

        <h1>Unseal Tamperproof NFTs</h1>
        <div className={styles.homeText}>
          Connect your wallet to unseal and redeem your prizes.
        </div>
        {loading ? (
          <Loading absolute={true} />
        ) : (
          <WalletMultiButton>
            {adapter?.publicKey ? publicKeySliced() : "Connect Wallet"}
          </WalletMultiButton>
        )}
      </div>
    </Layout>
  )
}

export default Home
