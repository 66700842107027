import classNames from "classnames"
import { useState, useRef, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useWallet } from "@solana/wallet-adapter-react"
import { getTensorAccounts } from "../../helpers/getTensorAccounts"
import styles from "./Header.module.scss"

import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"

const Header = () => {
  const [oneNFT, setOneNFT] = useState(null)
  const adapter = useWallet()
  const { publicKey } = useWallet()
  const history = useHistory()

  /*
  useEffect(() => {
    if (publicKey) {
      checkForNFTs()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (publicKey) {
      checkForNFTs()
    }
  }, [publicKey]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkForNFTs = async () => {
    let nfts = await getTensorAccounts(adapter)
    if (nfts.length === 1) {
      setOneNFT(nfts[0].mint.address.toString())
    }
  }
  */

  const publicKeySliced = () => {
    if (!publicKey) return ""
    let str = publicKey.toString()
    if (!publicKey) return ""
    let start = str.substr(0, 4)
    let end = str.substr(str.length - 4, str.length)
    return `${start}...${end}`
  }

  // const dispenser = async () => {
  //   // let's hit a server side dispenser
  //   let dispenserUrl = `${process.env.REACT_APP_EXPRESS_API}/api/dispenser/ticketdex`
  //   if (user.loginMethod === "phone") {
  //     let expressUrl = `${process.env.REACT_APP_EXPRESS_API}/api/ticketdexDispenser`
  //     let headers = {
  //       "Content-Type": "application/json",
  //     }
  //     let data = {
  //       url: dispenserUrl,
  //       diner_uuid: user.uuid,
  //     }
  //     try {
  //       let resp = await fetch(expressUrl, {
  //         method: "post",
  //         headers: headers,
  //         body: JSON.stringify(data),
  //       })
  //       console.log("resp", resp)
  //       resp = await resp.json()
  //       alert(resp.success)
  //     } catch (err) {
  //       alert("failure")
  //       console.log("restrictor error", err)
  //     }
  //   } else if (user.loginMethod === "wallet") {
  //     const res = await fetch(dispenserUrl, {
  //       method: "POST",
  //       body: JSON.stringify({
  //         account: user.publicKey.toString(),
  //       }),
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })

  //     if (!res.ok) {
  //       throw new TypeError("Invalid response")
  //     }
  //     const connection = new Connection(
  //       "https://shy-icy-water.solana-mainnet.quiknode.pro/b2e2d1cb7ef4ff5db6dd353035e14739a5904ffb"
  //     )
  //     const data = await res.json()
  //     const txEncoded = data.transaction
  //     const tx = Transaction.from(Base64.toUint8Array(txEncoded))
  //     const {
  //       context: { slot: minContextSlot },
  //       value: { blockhash, lastValidBlockHeight },
  //     } = await connection.getLatestBlockhashAndContext()

  //     const signature = await sendTransaction(tx, connection, {
  //       minContextSlot,
  //     })
  //     console.log("signature", signature)
  //     await connection.confirmTransaction({
  //       blockhash,
  //       lastValidBlockHeight,
  //       signature,
  //     })
  //   }
  // }

  // const restrictor = async () => {
  //   // let's hit the restrictor hardly know her
  //   let data = {
  //     account: user.publicKey.toString(),
  //   }
  //   let url = `${process.env.REACT_APP_EXPRESS_API}/api/velvet/ticketdex`
  //   let headers = {
  //     "Content-Type": "application/json",
  //   }
  //   try {
  //     let resp = await fetch(url, {
  //       method: "post",
  //       headers: headers,
  //       body: JSON.stringify(data),
  //     })
  //     console.log("resp", resp)
  //     resp = await resp.json()
  //     alert(resp.valid)
  //   } catch (err) {
  //     console.log("restrictor error", err)
  //   }
  // }

  const ref = useRef()

  return (
    <>
      <div className={styles.blackHeader} id="header" ref={ref}>
        <div className={styles.headerInner}>
          <img
            className={styles.headerLogo}
            alt="logo"
            style={{ cursor: "pointer" }}
            onClick={() =>
              window.open("/", "_blank")
            }
            src="https://cdn.hngr.co/tpinverted.png"
          />
          <div className={styles.headerRight}>
            <div
              onClick={() => {
                window.open("https://www.tamperproof.xyz/", "_blank")
              }}
              className={classNames(styles.headerLink)}
            >
              About
            </div>
            {publicKey && (
              <a
                onClick={() => history.push("/nfts")}
                className={classNames(styles.headerLink)}
              >
                My Rewards
              </a>
            )}

            {oneNFT && (
              <a
                onClick={() => history.push("/nft/" + oneNFT)}
                className={classNames(styles.headerLink)}
              >
                Unseal
              </a>
            )}

            {/* {user?.publicKey && false && (
              <Button onClick={restrictor}>Restrictor</Button>
            )}

            {user?.publicKey && false && (
              <Button onClick={dispenser}>Dispenser</Button>
            )} */}

            <WalletMultiButton>
              {publicKey ? publicKeySliced() : "Connect Wallet"}
            </WalletMultiButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
