import {
  setConfig,
  PDA,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  CNFT,
} from "@captainxyz/solana-core"

import { PublicKey } from "@solana/web3.js"

export const getTensorAccounts = async (adapter) => {
  // monkeys, tensor and xp
  const collectionMintAddresses = [
    "6VSFTQ22f7W98KwXDeRAMpcizSyAGQ1VrWgJTZvinKXH",
    "2dSDfnZQRpiEKBJomGwbe6i2pQ1Mqo5qLXhf5SH6Bp1X",
    "NZkmcbiRviZuu4CmwACYLiP8XAsCaXiTRiPr7hvZF7n",
    "FTJFivetDPeuj4TeB56Z4XCVo45vPSWBbKKcfE7YEgo3",
    "BqixEknfYqFq8esMb4kDzXVz1a8HNWVyP5Ggkhrbqd4t",
    "GSkwtD78Kop9zGK5P32Mq4Q2MfkQPUt2trBiNiJ2wmmg"
  ]
  //const collectionMintAddress = "2dSDfnZQRpiEKBJomGwbe6i2pQ1Mqo5qLXhf5SH6Bp1X"
  setConfig("mainnet-beta", {
    rpcEndpoint: process.env.REACT_APP_RPC,
  })

  const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
  let holder = new Operator("mainnet-beta", walletAdapterIdentity)
  let tensorTokens = []

  for (let i = 0; i < collectionMintAddresses.length; i++) {
    let maxPages = 1
    let collectionMintAddress = collectionMintAddresses[i]
    for (let page = 1; page <= maxPages; page++) {
      const result = await holder.rpc.searchAssets({
        limit: 1000,
        page: page,
        ownerAddress: holder.identity.publicKey.toString(),
        grouping: ["collection", collectionMintAddress],
        burnt: false,
        sortBy: {
          sortBy: "created",
          sortDirection: "desc",
        },
      })
      for (const asset of result.items) {
        const address = new PublicKey(asset.id)
        if (asset.compression?.compressed) {
          const cnft = await CNFT.init(holder, { address })
          // do something
        } else {
          try {
            const token = await TokenAccount.init(
              holder,
              PDA.token(address, holder.identity.publicKey)
            )
            tensorTokens.push(token)
            // do something
          } catch (err ) {
            console.log("error init account", err)
          }
        }
      }
      if (maxPages === 1 && result.total > result.limit) {
        maxPages = Math.ceil(result.total / result.limit)
      }
    }
  }

  return tensorTokens
}
