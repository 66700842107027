import React from "react";
import WalletContext from "./contexts/WalletContext";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route } from "react-router-dom";
import "./css/global.scss";

// routes
import Home from "./Home/Home";
import HomeStepTwo from "./Home/HomeStepTwo";
import NFTs from "./Holder/NFTs";
import NFT from "./Holder/NFT"

import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

if (process.env.REACT_APP_HNGR_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_HNGR_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

if (process.env.REACT_APP_LOGROCKET) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET);
}

const App = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Route path="/" exact component={Home} />
      <Route path="/enter" exact component={HomeStepTwo} />
      <Route path="/nfts" exact component={NFTs} />
      <Route path="/nft/:mint" exact component={NFT} />
    </div>
  )
};

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <BrowserRouter>
    <WalletContext>
      <App />
    </WalletContext>
  </BrowserRouter>
);
