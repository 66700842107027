import React from "react"
import styles from "./Footer.module.scss"

const Footer = () => {
  return (
    <div className={styles.footerContainer}>
      Safely secured with <a style={{color:'white'}} href='https://tamperproof.xyz' target='_blank'>Tamperproof.xyz</a>
    </div>
  )
}

export default Footer
