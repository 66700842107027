import { useEffect, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { useParams } from "react-router-dom"
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui"
import classNames from "classnames"
import {
  setConfig,
  WalletAdapterIdentity,
  Operator,
  TokenAccount,
  MintAccount,
  PDA,
} from "@captainxyz/solana-core"

// DO NOT DELETE THESE OR THE WHOLE THING WON'T WORK FOR SOME REASON
import {
  SealedSecret,
  isTamperproofHolder, // eslint-disable-line no-unused-vars
  isTamperproofOracle, // eslint-disable-line no-unused-vars
} from "@captainxyz/tamperproof"

import { PublicKey, Connection } from "@solana/web3.js"
import { Button, Layout, Modal, Tag } from "../components"
import Loading from "../Loading/Loading"
import ErrorModal from "./ErrorModal"
import UnsealModal from "./UnsealModal"
import UnsealModalMobile from "./UnsealModalMobile"
import UnsealedModal from "./UnsealedModal"

import styles from "./NFT.module.scss"

import postToSlack from "../postToSlack"
import * as Sentry from "@sentry/browser"
import { AngleRight, SolanaFM } from "../css/icons"

const NFT = () => {
  const [secret, setSecret] = useState(null)
  const [token, setToken] = useState(false)
  const [holderKey, setHolderKey] = useState(false)
  const [unownedToken, setUnownedToken] = useState(false)
  const [owner, setOwner] = useState(null)
  const [isOwner, setIsOwner] = useState(null)
  const [sealed, setSealed] = useState(null)
  const [unsealed, setUnsealed] = useState(null)
  const [showUnsealModal, setShowUnsealModal] = useState(false)
  const [showUnsealModalMobile, setShowUnsealModalMobile] = useState(false)
  const [showUnsealingModal, setShowUnsealingModal] = useState(false)
  const [showUnsealedModal, setShowUnsealedModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [unsealSuccess, setUnsealSuccess] = useState(false)
  const { mint } = useParams()

  const adapter = useWallet()

  window.adapter = adapter
  useEffect(() => {
    getUnownedToken()
    getOwner()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter?.publicKey) {
      getToken()
    }
  }, [adapter]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (unsealSuccess) {
      setShowUnsealingModal(true)

      setTimeout(() => {
        setShowUnsealingModal(false)
      }, 4000)
    }
  }, [unsealSuccess])

  useEffect(() => {
    if (adapter.signMessage) {
      if (adapter.publicKey) {
        postToSlack(`${adapter.publicKey.toString()} is viewing NFT ${mint}`)
      }
    }
  }, [adapter.signMessage]) // eslint-disable-line react-hooks/exhaustive-deps

  const getOwner = async () => {
    try {
      let connection = new Connection(process.env.REACT_APP_RPC)
      const largestAccounts = await connection.getTokenLargestAccounts(
        new PublicKey(mint)
      )
      const largestAccountInfo = await connection.getParsedAccountInfo(
        largestAccounts.value[0].address
      )
      let owner = largestAccountInfo.value.data.parsed.info.owner
      setOwner(owner.toString())
    } catch (err) {
      console.log("Error getting owner", err)
    }
  }

  const getUnownedToken = async () => {
    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })
    let operator = new Operator("mainnet-beta")
    let token = await MintAccount.init(operator, new PublicKey(mint))
    if (token.metadata._json.secret.state === "sealed") {
      setSealed(true)
    } else {
      setUnsealed(true)
    }
    console.log("unowned token", token)
    setUnownedToken(token)
  }

  const getToken = async () => {
    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })
    let operator
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    operator = new Operator("mainnet-beta", walletAdapterIdentity)

    let pubKey = new PublicKey(adapter?.publicKey)
    let tokenAddress = PDA.token(new PublicKey(mint), pubKey)
    let token
    try {
      token = await TokenAccount.init(operator, tokenAddress)
      if (!token.balance.qty) {
        setIsOwner(false)
        return
      }
    } catch (err) {
      if (err.toString().indexOf("Account not found ") > -1) {
        setIsOwner(false)
        return
      } else {
        console.log("error getting token account", err)
        return
      }
    }
    setIsOwner(true)

    if (token.canRequestUnsealing) {
      // eslint-disable-next-line eqeqeq
      if (token.mint.address.toString() == mint) {
        setToken(token)
        return
      }
    }

    if (token.canDecrypt) {
      // eslint-disable-next-line eqeqeq
      if (token.mint.address.toString() == mint) {
        setToken(token)
        return
      }
    }
  }

  const unsealWithAdapter = async () => {
    // if we somehow got here with an already unsealed one
    if (token.canDecrypt) {
      setShowUnsealingModal(false)
      setShowUnsealedModal(true)
      return
    }

    let a
    try {
      //mobile
      if(holderKey){
        a = await token?.requestUnsealingWithKey(holderKey)
      } else {
        a = await token?.requestUnsealing()
      }
    } catch (err) {
      console.log("request unseal error", err)
      Sentry.captureException(err)
      if (err.message && err.message.indexOf("has not been authorized") > -1) {
        alert("There may be an issue with Phantom. Please refresh the page.")
      }
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Bug with unsealing")
      })
      setShowUnsealingModal(false)
      setShowErrorModal(true)
      return
    }
    let url = `${process.env.REACT_APP_EXPRESS_API}/api/tamperproofRequestReveal`
    console.log("process", process.env)
    let headers = {
      "Content-Type": "application/json",
    }
    let params = {
      wallet: adapter.publicKey.toString(),
      sig: a.signature,
      mint: token.mint.address.toString(),
      oracle_id: token.metadata._json.oracle_id,
    }
    try {
      let resp = await fetch(url, {
        method: "post",
        headers: headers,
        body: JSON.stringify(params),
      })
      console.log("process", process.env)
      resp = await resp.json()
      if (resp.success) {
        await getToken()
        await getUnownedToken()
        setUnsealSuccess(true)
        setShowUnsealingModal(false)
        setShowUnsealedModal(true)
        postToSlack(
          `${adapter.publicKey.toString()} succesfully unsealed for ${mint}`
        )
        // todo: make these the same variable, because having two is gross
        setUnsealed(true)
        setSealed(false)
      } else {
        postToSlack(
          `${adapter.publicKey.toString()} captured unsealed error for ${mint}`
        )
        setShowUnsealingModal(false)
        setShowErrorModal(true)
      }
    } catch (err) {
      console.log(err)
      postToSlack(
        `${adapter.publicKey.toString()} unkown unsealed error for ${mint}`
      )
      setShowUnsealingModal(false)
      setShowErrorModal(true)
    }
  }

  const deriveKey = async () => {
    setConfig("mainnet-beta", {
      rpcEndpoint: process.env.REACT_APP_RPC,
    })
    let operator
    const walletAdapterIdentity = new WalletAdapterIdentity(adapter)
    operator = new Operator("mainnet-beta", walletAdapterIdentity)
    const secret = SealedSecret.decode(token.metadata._json.secret)
    const holderKey = await secret.getPublicKey(walletAdapterIdentity)
    setHolderKey(holderKey)
  }

  const unseal = async () => {
    setShowErrorModal(false)
    setShowUnsealModal(false)
    setShowUnsealingModal(true)
    postToSlack(`${adapter.publicKey} is requesting unseal for ${mint}`)
    unsealWithAdapter()
  }

  const unsealMobile = async () => {
    setShowErrorModal(false)
    setShowUnsealModal(false)
    setShowUnsealingModal(true)
    postToSlack(`${adapter.publicKey} is requesting unsealing on mobile for ${mint}`)
    unsealWithAdapter()
  }

  const decryptFromModal = async () => {
    postToSlack(
      `${adapter.publicKey.toString()} decrypted from modal for ${mint}`
    )
    try {
      let secret = await token.decrypt()
      setSecret(secret)
      setShowUnsealedModal(false)
    } catch (err) {
      Sentry.captureException(err)
      if (err.message && err.message.indexOf("has not been authorized") > -1) {
        Sentry.configureScope((scope) => {
          Sentry.captureMessage("Bug with decrypting from modal")
        })
        alert("There may be an issue with Phantom. Please refresh the page.")
      }
    }
  }

  const decrypt = async () => {
    let secret
    try {
      secret = await token.decrypt()
    } catch (err) {
      Sentry.configureScope((scope) => {
        Sentry.captureMessage("Bug with decrypting from nft page")
      })
      Sentry.captureException(err)
      if (err.message && err.message.indexOf("has not been authorized") > -1) {
        alert("There may be an issue with Phantom. Please refresh the page.")
      }
      console.log("error", err)
      alert("error")
      return
    }
    setSecret(secret)
    postToSlack(
      `${adapter.publicKey.toString()} decrypted from nft page for ${mint}`
    )
  }

  const submitCode = async (code, email) => {
    let url = `${process.env.REACT_APP_HNGR_API}/ticketdex/claim-code`
    let headers = {
      "Content-Type": "application/json",
    }
    let params = { code: code, email: email }
    await fetch(url, {
      method: "post",
      headers: headers,
      body: JSON.stringify(params),
    })
  }

  const getReward = () => {
    try {
      let attributes = token.metadata._json.attributes
      for (let a of attributes) {
        if (a.trait_type == "Reward") {
          return a.value
        }
      }
    } catch (err) {
      return null
    }
  }

  /*
 Raposa Coffee Co. $50 Gift Card
 $420.69 Saga from Solana Mobile
 Meegos WL Code
 Raposa Coffee Co. $20 Gift Card
 XP Crystal Chip
 Saga 10% Off Discount 
 In-Game horse from Photo Finish
 FREE Saga Phone
 FLOOR App Access Code
 Raposa Coffee Co. $100 Gift Card
 Metame Whitelist Code
 XP Gold Chip
 Free Onda Tee-Shirt
 Claynosaurz 1:1 Team Member Chat
 Sleepr $10 Off
 Raposa Coffee Co. 20% Discount
  */

  const getUnsealingImage = () => {
    return token?.metadata?._json.image
  }

  /*
Metame, Raposa, Solana Mobile. Meegos, Onda, Floor, Sleepr, Photofinish Live, Claynosaurz, MonkeDao (edited) 
*/

  const getCompanyName = () => {

  }

  const publicKeySliced = (key) => {
    if (!key) return ""
    let str = key.toString()
    if (!key) return ""
    let start = str.substr(0, 4)
    let end = str.substr(str.length - 4, str.length)
    return `${start}...${end}`
  }

  const renderCTA = () => {
    if (adapter.publicKey && isOwner) {
      return (
        <>
          {!unsealed && (
            <Button
              variant="green"
              fullWidth
              onClick={() => {
                if(isMobile()){
                  setShowUnsealModalMobile(true)
                } else {
                  setShowUnsealModal(true)
                }
              }}
            >
              Break Seal & Reveal Code
            </Button>
          )}
          {unsealed && !secret && (
            <Button
              variant="green"
              className={styles.button}
              fullWidth
              onClick={() => decrypt()}
            >
              Reveal Code
            </Button>
          )}
          {unsealed && secret && (
            <a
              href={token.metadata._json.external_url}
              target="_blank"
              style={{ textDecoration: "none" }}
            >
              <Button
                variant="green"
                fullWidth
                onClick={() => {}}
                className={styles.button}
              >
                Redeem prize at Prize Website
              </Button>
            </a>
          )}
        </>
      )
    } else if (adapter.publicKey && !isOwner) {
      return (
        <div>
          <Button variant="green" fullWidth disabled className={styles.button}>
            Break Seal & Reveal Code
          </Button>
          <div className={styles.alert}>
            ⚠️ Cannot unseal. NFT owned by {publicKeySliced(owner)}
          </div>
        </div>
      )
    } else {
      return <WalletMultiButton>Connect Wallet</WalletMultiButton>
    }
  }

  return (
    <>
      {showUnsealModal && (
        <UnsealModal
          token={token}
          close={() => {
            document.body.style.overflow = "auto"
            setShowUnsealModal(false)
          }}
          unseal={() => unseal()}
        />
      )}

      {showUnsealModalMobile && (
        <UnsealModalMobile
          token={token}
          close={() => {
            document.body.style.overflow = "auto"
            setShowUnsealModalMobile(false)
          }}
          holderKey={holderKey}
          unsealMobile={()=>unsealMobile()}
          deriveKey={() => deriveKey()}
        />
      )}

      {showUnsealedModal && (
        <UnsealedModal
          close={() => setShowUnsealedModal(false)}
          token={token}
          decrypt={() => decryptFromModal()}
        />
      )}
      {showErrorModal && (
        <ErrorModal
          token={token}
          unseal={() => unseal()}
          close={() => setShowErrorModal(false)}
        />
      )}
      {showUnsealingModal && (
        <Modal width="medium">
          <div className={styles.unsealingModalContainer}>
            <div className={styles.unsealLoading}>
              <h1>Unsealing Prize...</h1>
              <p>This may take up to 60 seconds</p>
              <img src={getUnsealingImage()} alt="Unsealing" />
              <Button variant="green" disabled fullWidth>
                Unsealing...
              </Button>
              <p>Do not exit or reload this page</p>
            </div>
          </div>
        </Modal>
      )}
      <Layout>
        {unownedToken ? (
          <div className={styles.contentContainer}>
            <div className={styles.contentLeft}>
              <img
                alt="cube"
                src={unownedToken.metadata._json.image}
                className={styles.image}
              />
            </div>

            <div className={styles.contentRight}>
              <div className={styles.contentHeader}>
                <div>
                  <Tag
                    content={sealed ? "Sealed for Authenticity" : "Unsealed"}
                    variant={sealed ? "sealed" : "claimed"}
                  />
                </div>
                <div className={styles.stepContainer}>
                  Step {sealed ? 1 : 2}/2
                  <div className={styles.stepActive}></div>
                  <div
                    className={classNames(
                      styles.stepDisabled,
                      !sealed && styles.stepActive
                    )}
                  ></div>
                </div>
              </div>
              <h1>{unownedToken.metadata._json.name}</h1>
              <p>
                {sealed ? (
                  "Break the seal to unlock and reveal your hidden code."
                ) : secret ? (
                  <>
                    {token.metadata._json.description}
                  </>
                ) : (
                  `Reveal your code!`
                )}
              </p>

              <div className={styles.tokenDetails}>

                <div className={styles.tokenDetail}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <div className={styles.detailLabel}>Redeem Code</div>
                      <div className={styles.detailValue}>
                        {unsealed && !secret ? (
                          <span className={styles.secretReveal}>
                            Reveal your code to redeem a prize
                          </span>
                        ) : (
                          <>{secret ? secret : "??????"}</>
                        )}
                      </div>
                    </div>

                    <div>
                      {sealed && <span style={{ fontSize: "20px" }}>🔒</span>}

                      {unsealed && !secret && (
                        <span style={{ fontSize: "20px" }}>🎁</span>
                      )}

                      {secret && (
                        <div
                          onClick={() => {
                            try {
                              navigator.clipboard.writeText(secret)
                            } catch (err) {}
                          }}
                          className={styles.copyButtonContainer}
                        >
                          <div>Copy</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {renderCTA()}

              {adapter?.publicKey && (
                <div className={styles.metadata}>
                  <div>
                    Owned by{" "}
                    <span className={styles.ownerLabel}>
                      {token.owner?.toString() ===
                      adapter?.publicKey?.toString()
                        ? " (Me)"
                        : publicKeySliced(owner)}
                    </span>
                  </div>
                  <div
                    className={styles.solanaLinks}
                    onClick={() =>
                      window.open(
                        `https://solana.fm/address/${unownedToken.address.toString()}?cluster=mainnet-qn1`,
                        "_blank"
                      )
                    }
                  >
                    <SolanaFM />
                    <AngleRight />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </Layout>
    </>
  )
}

export default NFT

function isMobile() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera

  if (/android/i.test(userAgent)) {
    return true
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return true
  }

  return false
}
