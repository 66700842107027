import { useHistory } from "react-router-dom"
import { Tag } from "../index"
import styles from "./NFTListItem.module.scss"
import { Sealed, Unsealed } from "../../css/icons"

const NFTListItem = ({ nft }) => {
  const history = useHistory()
  const sealed = nft.metadata._json.secret.state === "sealed"

  return (
    <div
      className={styles.nftListItem}
      onClick={() => {
        let mint = nft.mint.address.toString()
        history.push(`/NFT/${mint}`)
      }}
    >
      <img
        alt="img"
        className={styles.nftImage}
        src={nft.metadata._json.image}
      />

      <div className={styles.nftStatus}>
        <Tag
          content={
            sealed ? (
              <span>
                <Sealed />
                Sealed
              </span>
            ) : (
              <span>
                <Unsealed />
                Unsealed
              </span>
            )
          }
          variant={sealed ? "sealed" : "claimed"}
        />

        <h1>{nft.metadata._json.name}</h1>
        <p>{nft.metadata.json.type}</p>
      </div>
    </div>
  )
}
export default NFTListItem
