import { AccountInfo, PublicKey } from "@solana/web3.js";

import { Operator } from "../operator";
import { SOL, Amount } from "../currency";

/**
 * Base interface for account extensions,
 * which add extra functionality to accounts.
 *
 * @group Accounts
 */
export interface AccountExtension {
  /**
   * Checks if the extension is applicable to the account.
   */
  isApplicable(account: BaseAccount): boolean;

  /**
   * Callback to run after the extenstion is applied to the account.
   */
  onApply?(account: BaseAccount): unknown;

  /**
   * Extra methods to add to the account.
   */
  methods?: { [key: string]: (...args: any[]) => unknown };

  /**
   * Extra properties to add to the account.
   */
  properties?: {
    [key: string]:
      | {
          /**
           * Getter for the property.
           */
          get: () => unknown;

          /**
           * Setter for the property.
           */
          set?: (value: unknown) => void;
        }
      | {
          /**
           * Value of the property.
           *
           * If the value is a function,
           * it will be used to calculate the actual value.
           *
           * Async functions and promises will be awaited.
           */
          value: Function | Promise<any> | any;

          /**
           * Is the property is writable? Default is `false`.
           */
          writable?: boolean;
        };
  };
}

/**
 * @group Accounts
 */
export class BaseAccount {
  /**
   * Account extensions, which add extra functionality to accounts.
   */
  public static readonly extensions: Set<AccountExtension> = new Set();

  /**
   * Account's operator.
   */
  public readonly operator: Operator;

  /**
   * Address of the account.
   */
  public readonly address: PublicKey;

  /**
   * Amount of {@link SOL | SOLs} allocated on the account for rent excempt.
   */
  public readonly rent: Amount;

  /**
   * Event, which is triggered when the account is refreshed.
   * For example its balance is changed.
   */
  public readonly onRefresh: AccountEvent;

  /**
   * Event, which is triggered when the account is deactivated.
   * Inactive accounts are not refreshed and do not trigger {@link onRefresh} event.
   *
   * @see {@link isActive}.
   */
  public readonly onDeactivate: AccountEvent;

  /**
   * Returns activity state of the account.
   * Inactive accounts are not refreshed and do not trigger {@link onRefresh} event.
   */
  public get isActive(): boolean {
    return this._isActive;
  }
  protected _isActive: boolean;

  protected constructor(params: {
    operator: Operator;
    address: PublicKey;
    lamports: number | bigint;
  }) {
    this.operator = params.operator;
    this.address = params.address;
    this.rent = SOL.amountFromQty(params.lamports);

    this.onRefresh = new AccountEvent();
    this.onDeactivate = new AccountEvent();

    this._isActive = true;
    this._refreshed = performance.now();
    this._cache = new Map();

    /*
    const listenerId = this.operator.connection.onAccountChange(
      this.address,
      (accountInfo) => this.refresh(accountInfo)
    );
    this.onDeactivate.subscribe(() =>
      this.operator.connection.removeAccountChangeListener(listenerId)
    );
    */

  }

  /**
   * Initializes the account.
   */
  protected async _init<T extends BaseAccount>(this: T): Promise<T> {
    await this.operator.attach(this);
    await Promise.all(
      [...BaseAccount.extensions].map((ext) => this._applyExtenstion(ext))
    );
    return this;
  }

  /**
   * Checks if the account is already initialized,
   * then refreshes it using `accountInfo` and returns it.
   *
   * @see {@link Operator.getAccount}.
   */
  protected static async _checkExistent<T extends BaseAccount>(
    operator: Operator,
    address: PublicKey,
    accountInfo?: AccountInfo<Buffer> | null
  ): Promise<T | undefined> {
    const existent = operator.getAccount<T>(address);
    if (existent) await existent.refresh(accountInfo);
    return existent;
  }

  /**
   * Applies extensions to the account.
   */
  protected async _applyExtenstion(extension: AccountExtension): Promise<void> {
    if (!extension.isApplicable(this)) return;
    if (extension.methods) {
      for (const [name, method] of Object.entries(extension.methods)) {
        Object.defineProperty(this, name, { value: method });
      }
    }
    if (extension.properties) {
      for (const [name, property] of Object.entries(extension.properties)) {
        if ("value" in property) {
          if (typeof property.value === "function") {
            property.value = property.value();
          }
          if (property.value instanceof Promise) {
            property.value = await property.value;
          }
        }
        Object.defineProperty(this, name, property);
      }
    }
    if (extension.onApply) await extension.onApply(this);
  }

  /**
   * Returns string representation of the account.
   */
  public toString(): string {
    return `<${this.address}: { rent: ${this.rent} }>`;
  }

  /**
   * Deactivates the account.
   *
   * The method cleans up account resources,
   * updates activity state — {@link isActive},
   * and triggers {@link onDeactivate} event.
   *
   * @param detach - If `true`, the account is detached from the operator.
   *
   * The parameter is not intended to be used by the end user,
   * it used iternally by the operator to optimize its closing routine.
   *
   * @see {@link Operator.close}, {@link Operator.detach}.
   */
  public async deactivate(detach: boolean = true): Promise<void> {
    if (this._isActive) {
      this._isActive = false;
      await this.onDeactivate.trigger(this);
      this.onDeactivate.deactivate();
      this.onRefresh.deactivate();
      if (detach) await this.operator.detach(this);
    }
  }

  /**
   * Internal cache, which is invalidated on each refresh.
   */
  protected _cache: Map<string, any>;

  /**
   * Returns value from cache by its `key`.
   * If value is not found, it is calculated by `calculate` callback.
   */
  protected _getCached<T>(key: string, calculate: () => T): T {
    let value = this._cache.get(key) as T | undefined;
    if (typeof value === "undefined") {
      value = calculate();
      this._cache.set(key, value);
    }
    return value;
  }

  /**
   * Ensures {@link onRefresh} event has been triggered after given timestamp.
   *
   * The metod is used within tests to avoid race conditions.
   *
   * @param timestamp
   * Should be acquired by `performance.now()`
   * before an action which triggers the account refresh.
   */
  public async ensureRefreshedAfter(timestamp: number): Promise<void> {
    if (this._refreshed < timestamp) {
      return await new Promise((resolve) => {
        const id = this.onRefresh.subscribe((account) => {
          if (account._refreshed >= timestamp) {
            resolve();
            this.onRefresh.unsubscribe(id);
          }
        });
      });
    }
  }

  /**
   * Timestamp of last refresh event.
   */
  protected _refreshed: number;

  /**
   * Refreshes account with updated info.
   */
  protected async _refresh(accountInfo: AccountInfo<Buffer>): Promise<void> {
    this._refreshed = performance.now();
    this._cache.clear();
    this.rent.qty = accountInfo.lamports;
  }

  /**
   * Refreshes account with updated info.
   *
   * @param accountInfo Optional account info.
   *
   * If it is not provided and there is more than 1 second since last refresh,
   * the method will fetch it and refresh the account.
   *
   * If it is `true`, the method will always fetch it and refresh the account.
   */
  public async refresh(
    accountInfo?: AccountInfo<Buffer> | true | null
  ): Promise<void> {
    if (
      accountInfo === true ||
      (!accountInfo && performance.now() - this._refreshed > 1000)
    ) {
      accountInfo = await this.operator.connection.getAccountInfo(this.address);
    }
    if (accountInfo) {
      await this._refresh(accountInfo);
      await this.onRefresh.trigger(this);
    }
  }
}

/**
 * @group Accounts
 */
export interface SendInstruction {
  /**
   * Recipient address.
   */
  to: PublicKey;

  /**
   * Amount to send in base currency units.
   *
   * @see Currency.{@link Currency.decimals} for details.
   */
  value?: bigint | number | string;

  /**
   * Amount to send in atomic currency units.
   *
   * @see Currency.{@link Currency.decimals} for details.
   */
  qty?: bigint | number | string;

  /**
   * Optional memo for transfer.
   */
  memo?: string;
}

/**
 * @group Accounts
 */
export type AccountEventCallback = (account: BaseAccount) => unknown;

/**
 * @group Accounts
 */
export class AccountEvent {
  private _lastId: number;
  private _subscriptions: Map<number, AccountEventCallback>;

  public constructor() {
    this._lastId = 0;
    this._subscriptions = new Map();
  }

  /**
   * Subscribes callback function to the event.
   *
   * @return Subscription ID, which is used to {@link unsubscribe} the callback.
   */
  public subscribe(callback: AccountEventCallback): number {
    const id = this._lastId++;
    this._subscriptions.set(id, callback);
    return id;
  }

  /**
   * Unsubscribes callback function from the event
   * using previously obtained subscription ID.
   */
  public unsubscribe(id: number): void {
    this._subscriptions.delete(id);
  }

  /**
   * Unsubscribe all callbacks from the event.
   */
  public deactivate(): void {
    this._subscriptions.clear();
  }

  /**
   * Trigger the event, i.e. invoke all subribed callbacks.
   */
  public async trigger(account: BaseAccount): Promise<void> {
    await Promise.all(
      [...this._subscriptions.values()].map((callback) => callback(account))
    );
  }
}
