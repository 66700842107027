import { useHistory } from "react-router-dom"
import { useWallet } from "@solana/wallet-adapter-react"
import { Button, Layout } from "../components"
import styles from "./HomeStepTwo.module.scss"

const HomeStepTwo = () => {
  const { publicKey } = useWallet()
  const history = useHistory()

  const goToNFTs = () => {
    history.push("/nfts")
  }

  const next = () => {
    if (publicKey) goToNFTs()
  }

  return (
    <Layout>
      <h1 style={{ color: "white" }}>Win Big with tpNFTs</h1>
      <Button outline onClick={next}>
        Unseal
      </Button>
    </Layout>
  )
}

export default HomeStepTwo
