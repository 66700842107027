import classNames from "classnames"
import { Button, Modal } from "../components"
import styles from "./UnsealModal.module.scss"
import { CloseCircle } from "../css/icons"

const UnsealedModal = ({ close, decrypt, token }) => {
  const getImage = () => {
    if (token.metadata?._json?.secret?.onUnseal?.image) {
      return token.metadata?._json?.secret?.onUnseal?.image
    } else {
      return token.metadata?._json?.image
    }
  }

  return (
    <Modal onClose={() => close()} width="medium">
      <div className={styles.unsealModalContainer}>
        <CloseCircle className={styles.close} onClick={() => close()} />
        <div className={classNames(styles.unsealWarning, styles.unsealed)}>
          Prize Unsealed
        </div>
        <div className={styles.subtext}>
          This Tamperproof NFT Prize is now unsealed on the public ledger.
        </div>

        <div className={styles.imageContainer}>
          <img src={getImage()} alt="unsealed" className={styles.image} />
          <div className={styles.unsealedTag}>Ready to Reveal</div>
        </div>
        <br />
        <br />

        <div className={styles.buttonContainer}>
          <Button variant="green" onClick={() => decrypt()}>
            Reveal Code
          </Button>
        </div>
        <br />
        <br />
        <div className={styles.link} onClick={() => close()}>
          I'll do it later
        </div>
      </div>
    </Modal>
  )
}

export default UnsealedModal
