import { useEffect, useState } from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { Link, useLocation } from "react-router-dom"

import { Button, Layout, NFTListItem } from "../components"

import styles from "./NFTs.module.scss"
import { getTensorAccounts } from "../helpers/getTensorAccounts"

require("@solana/wallet-adapter-react-ui/styles.css")

const HolderNFTs = () => {
  const [loading, setLoading] = useState(false)
  const [nfts, setNFTs] = useState([])

  const adapter = useWallet()
  const location = useLocation()

  useEffect(() => {
    if (adapter.publicKey) {
      loadNFTs()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adapter.publicKey) {
      loadNFTs()
    }
  }, [adapter]) // eslint-disable-line react-hooks/exhaustive-deps

  const loadNFTs = async () => {
    if (location?.state?.empty) {
      return
    }

    setLoading(true)
    let nfts = await getTensorAccounts(adapter)
    setLoading(false)
    setNFTs(nfts)
  }

  if (loading) {
    return (
      <Layout>
        <div className={styles.loadingContainer}>
          <img
            src="https://cdn.hngr.co/tamperproof/landingspinner.gif"
            className={styles.spinner}
            alt="spinner"
          />
          <div style={{ marginTop: "32px" }} className={styles.loadingSubtext}>
            Scanning your wallet for Tamperproof NFTs
          </div>
          <div style={{ marginTop: "8px" }} className={styles.loadingSubtext}>
            If you have a large NFT collection, this will take a moment
          </div>
        </div>
      </Layout>
    )
  }

  return (
    <>
      <Layout>
        <div className={styles.pageContainer}>
          <div className={styles.nftContainer}>
            {nfts.length > 0 ? (
              <div>
                <div className={styles.header}>
                  <h1>Tamperproof NFTs</h1>
                </div>
                <div className={styles.nftListContainer}>
                  {nfts.map((nft, i) => (
                    <NFTListItem key={nft.mint.address.toString()} nft={nft} />
                  ))}{" "}
                </div>
              </div>
            ) : (
              <div className={styles.noUpcoming}>
                <h2>No Tamperproof NFTs Detected</h2>
                <p>
                  If this is a mistake, please{" "}
                  <a href="https://www.tamperproof.xyz/contact">contact us.</a>
                </p>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default HolderNFTs
