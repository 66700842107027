import classNames from "classnames"
import Footer from "../Footer/Footer"
import Header from "../Header/Header"
import styles from "./Layout.module.scss"

const Layout = ({ children, className }) => (
  <div className={classNames(styles.pageContainer, className)}>
    <Header />
    <div className={styles.contentContainer}>{children}</div>
    <Footer />
  </div>
)

export default Layout
