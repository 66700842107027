export const Unsealed = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_911_5215)">
      <path
        d="M4.375 4.5L10 4.5C10.8273 4.5 11.5 5.17266 11.5 6L11.5 10.5C11.5 11.3273 10.8273 12 10 12L2.5 12C1.67148 12 0.999999 11.3273 0.999999 10.5L0.999999 6C1 5.17266 1.67148 4.5 2.5 4.5L2.875 4.5L2.875 3.375C2.875 1.51102 4.38672 2.96076e-07 6.25 4.5897e-07C7.59766 5.76785e-07 8.75781 0.78961 9.29922 1.9282C9.47734 2.30227 9.31797 2.74922 8.92422 2.92734C8.54922 3.10547 8.12266 2.94609 7.94453 2.57109C7.64219 1.93664 6.99766 1.5 6.25 1.5C5.21406 1.5 4.375 2.33953 4.375 3.375L4.375 4.5Z"
        fill="#FCCC3B"
      />
    </g>
    <defs>
      <clipPath id="clip0_911_5215">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
