import { Button, Modal } from "../components"
import { CloseCircle } from "../css/icons"
import styles from "./ErrorModal.module.scss"

const ErrorModal = ({ close, unseal, token }) => (
  <Modal onClose={() => close()}>
    <div className={styles.container}>
      <CloseCircle className={styles.close} onClick={() => close()} />
      <h1 className={styles.header}>Error</h1>

      <div className={styles.subtext}>
        Something went wrong. Your NFT prize was not unsealed.
      </div>
      <img
        src={token?.metadata?._json?.image}
        className={styles.image}
      />
      <br/><br/>
      <div className={styles.buttonContainer}>
        <Button variant="blue" onClick={() => unseal()}>
          Try again
        </Button>
      </div>
      <div className={styles.link} onClick={() => close()}>
        Back
      </div>
    </div>
  </Modal>
)

export default ErrorModal
